import classNames from 'classnames'
import './style.css'

const Footer = (props) => (
    <div className={classNames(
        'footer',
        props.className,
    )}>
        <p>&copy; мы 2020–2023</p>
        <p>* Не можем не уточнить, что организации, отмеченные звездочкой, запрещены в России</p>
    </div>
)

export default Footer