import classNames from 'classnames'

import imgApplePodcasts from './res/apple-podcasts.svg'
import imgInstagram from './res/instagram.svg'
import imgRutube from './res/rutube.svg'
import imgTechnics from './res/technics.svg'
import imgTelegram from './res/telegram.svg'
import imgWeb from './res/web.svg'
import imgYandexMusic from './res/yandex-music.svg'
import imgYouTube from './res/youtube.svg'

import './style.css'

const Icon = (props) => {
    const iconList = {
        'apple-podcasts': {
            title: 'Эпл-подкасты',
            src: imgApplePodcasts,
        },
        'instagram': {
            title: 'Инстаграм',
            src: imgInstagram,
        },
        'rutube': {
            title: 'Рутуб',
            src: imgRutube,
        },
        'technics': {
            title: 'Техники',
            src: imgTechnics,
        },
        'telegram': {
            title: 'Телеграм',
            src: imgTelegram,
        },
        'web': {
            title: 'Сайт',
            src: imgWeb,
        },
        'yandex-music': {
            title: 'Яндекс-музыка',
            src: imgYandexMusic,
        },
        'youtube': {
            title: 'Ютуб',
            src: imgYouTube,
        },
    }
    const icon = props.type && iconList[props.type] ? iconList[props.type] : null

    return (
        !!icon &&
        <div className={classNames(
            props.className, 
            'icon', 
            'icon_' + props.type,
        )}>
            <img src={icon.src} alt={icon.title} />
        </div>
    )
}

export default Icon