import classNames from 'classnames'
import './style.css'

const SectionHeader = (props) => (
    <div 
        className={classNames(
            'section__header',
            props.className,
        )}
    >
            <img className="section__header-background" src={props.background} alt="" />
            {props.children}
            <div className="section__header-text">{!!props.soon && <span className="section__header-soon">Скоро! </span>}{props.text}</div>
    </div>
)

export default SectionHeader