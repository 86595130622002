import './index.css'

import ReactDOM from 'react-dom'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import ScrollToTop from "component/ScrollToTop"

import Error404Route from 'route/Error404'
import IndexRoute from 'route/Index'

function App() {
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="/" element={<IndexRoute />} />
        <Route path="*" element={<Error404Route />} />
        {/* <Route path="/superjob/" element={<Navigate replace to="/superjob/identity" />} /> */}
        {/* <Route path="/superjob/identity" element={<SuperJobIdentityRoute />} /> */}
      </Routes>
    </BrowserRouter>
  );
}

ReactDOM.createRoot(document.getElementById("root")).render(
  <App />
);