import Date from 'component/Date'
import Icon from 'component/Icon'
import Roles from 'component/Roles'
import SectionHeader from 'component/SectionHeader'

import imgHeaderBackground from './res/header-background.svg'
import imgHeaderItem1 from './res/header-item-1.svg'
import imgCover from './res/cover.svg'

import './style.css'

const TechnicsSection = () => (
    <div className="section section_technics">
        <div className="section_technics__cover">
            <img src={imgCover} alt="Подкаст «Хорошие новости»" />
        </div>
        <div className="section_technics__info">
            <SectionHeader 
                className="section_technics__header" 
                text="Техники 2.0"
                background={imgHeaderBackground}
            >
                <img className="section_technics__header-item-1" src={imgHeaderItem1} alt="" />
            </SectionHeader>
            <p>Техники — это приемы для улучшения жизни. Они помогают стать эффективнее и счастливее: больше успевать, не упускать важное, шаг за шагом идти к цели, не забывать отдыхать.</p>
            <Roles list={['higimo', 'belostotskaya', 'vatrikovsky']} />
            <Date start='2021' end='2022' />
        </div>
        <div className="section_technics__links">
            Читайте <a href="https://tech.vatrikovsky.ru/" target="_blank" rel="noreferrer"><Icon type="technics" />на сайте</a> или <a href="https://t.me/efficient_and_happy" target="_blank" rel="noreferrer"><Icon type="telegram" />в Телеграме</a>
        </div>
    </div>
)

export default TechnicsSection