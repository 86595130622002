import classNames from 'classnames'
import React from 'react'

import members from 'data/members'

import './style.css'

const Roles = (props) => {
    let roleList = props.list && props.list.map((person, index) => (
        <React.Fragment>
            { index ? ', ' : null }
            { members[person]
                ? members[person].link
                    ? <a href={members[person].link} target="_blank" rel="noreferrer">{members[person].name}</a>
                    : <span>{members[person].name}</span>
                : null
            }
        </React.Fragment>
    ))

    return (
        <div 
            className={classNames(
                'roles',
                props.className,
            )}
        >
            <span className="roles__text">В ролях:</span> {roleList}
        </div>
    )
}

export default Roles