import classNames from 'classnames'

import Date from 'component/Date'
import Roles from 'component/Roles'
import SectionHeader from 'component/SectionHeader'

import imgHeaderBackground from './res/header-background.svg'
import imgHeaderItem1 from './res/header-item-1.svg'
import imgHeaderItem2 from './res/header-item-2.svg'

import imgEfficientAndHappy from './res/efficient-and-happy.svg'
import imgRakovarnya from './res/rakovarnya.svg'
import imgWantBetter from './res/want-better.svg'

import './style.css'

const ChannelSection = () => {
    const channelList = [
        {
            img: imgEfficientAndHappy,
            title: 'Техники → навыки → счастье',
            description: '180 приемов для эффективной и счастливой жизни',
            link: 'https://t.me/efficient_and_happy',
            roles: ['higimo', 'belostotskaya', 'vatrikovsky'],
            start: 2021,
            end: 2022
        },
        {
            img: imgRakovarnya,
            title: 'Раковарня 2.0',
            description: 'Про пользу, мотивацию, дизайн, кодинг и управление. В архиве.',
            link: 'https://t.me/rakovarnya_2_0',
            roles: ['vatrikovsky', 'legostaeff', 'higimo', 'aphhss'],
            start: 2020,
            end: 2023
        },
        {
            img: imgWantBetter,
            title: 'Хочу получше',
            description: 'Хиги́мо ведет дневник о том, как выводит жизнь на новый уровень',
            link: 'https://t.me/i_want_a_better',
            roles: ['higimo', 'belostotskaya'],
            start: 2022,
        },
        // {
        //     img: null,
        //     title: '',
        //     description: '',
        //     link: '',
        // },
    ]

    return (
        <div className="section section_channels">
            <SectionHeader 
                className="section_channels__header" 
                text="Каналы"
                background={imgHeaderBackground}
            >
                <img className="section_channels__header-item-1" src={imgHeaderItem1} alt="" />
                <img className="section_channels__header-item-2" src={imgHeaderItem2} alt="" />
            </SectionHeader>
            <div className="section_channels__list">
            {channelList.map(channel => (
                <a href={channel.link} target="_blank" rel="noreferrer" className={classNames("section_channels__item", channel.className)}>
                    <img className="section_channels__item-img" src={channel.img} alt="" />
                    <div className="section_channels__item-title">{channel.title}</div>
                    <div className="section_channels__item-description">{channel.description}</div>
                    <Roles list={channel.roles} />
                    {!!channel.start && <Date start={channel.start} end={channel.end} />}
                </a>
            ))}
            </div>
        </div>
    )
}

export default ChannelSection