import SectionHeader from 'component/SectionHeader'

import imgHeaderBackground from './res/header-background.svg'
import imgHeaderItem1 from './res/header-item-1.svg'
import imgHeaderItem2 from './res/header-item-2.svg'
import videoPromo from './res/promo.mp4'

import './style.css'

const LectionSection = () => (
    <div className="section section_lection">
        <div
            className="section_lection__video"
            dangerouslySetInnerHTML={{
                __html: `
                <video
                loop
                muted
                autoplay
                playsinline
                preload="metadata"
                >
                <source src="${videoPromo}" type="video/mp4" />
                </video>`
            }}
        />
        <SectionHeader 
            className="section_lection__header" 
            text="Рассказ «Как мы научились не выгорать и не профачивать»"
            background={imgHeaderBackground}
            soon
        >
            <img className="section_lection__header-item-1" src={imgHeaderItem1} alt="" />
            <img className="section_lection__header-item-2" src={imgHeaderItem2} alt="" />
        </SectionHeader>
    </div>
)

export default LectionSection