import classNames from 'classnames'
import './style.css'

const Date = (props) => {

    return (
        <div 
            className={classNames(
                'date',
                props.className,
            )}
        >
            <p>{props.start}–{props.end || '…' }</p>
        </div>
    )
}

export default Date