import Icon from 'component/Icon'

import imgAphhss from './res/aphhss.jpg'
import imgBelostotskaya from './res/belostotskaya.jpg'
import imgHigimo from './res/higimo.jpg'
import imgLegostaeff from './res/legostaeff.jpg'
import imgMatharm from './res/matharm.png'
import imgVatrikovsky from './res/vatrikovsky.jpg'

const members = {
    'vatrikovsky': {
        name: 'Рома Ватриковский',
        img: imgVatrikovsky,
        links: [
            {
                title: 'vatrikovsky.ru',
                url: 'https://vatrikovsky.ru'
            },
            // {
            //     title: 'Ватриковский думает',
            //     url: 'https://t.me/vatrikovsky_thinks',
            //     icon: <Icon type="telegram" />
            // },
            {
                title: 'Ватриковский пишет',
                url: 'https://t.me/vatrikovsky_writes',
                icon: <Icon type="telegram" />
            },
        ]
    },
    'higimo': {
        name: 'Дима Уткин',
        img: imgHigimo,
        links: [
            {
                title: 'higimo.ru',
                url: 'https://higimo.ru'
            },
            {
                title: 'Хиги́мо',
                url: 'https://t.me/higimoblog',
                icon: <Icon type="telegram" />
            },
            {
                title: 'Скриншотил',
                url: 'https://t.me/screenshotil',
                icon: <Icon type="telegram" />
            },
        ]
    },
    'legostaeff': {
        name: 'Дима Легостаев',
        img: imgLegostaeff,
        links: [
            {
                title: 'legostaeff.me',
                url: 'https://legostaeff.me'
            },
            {
                title: 'Дима Легостаев сделал',
                url: 'https://t.me/legostaeffdraw',
                icon: <Icon type="telegram" />
            },
        ]
    },
    'belostotskaya': {
        name: 'Аня Белостоцкая',
        img: imgBelostotskaya,
    },
    'aphhss': {
        name: 'Марина Потапова',
        img: imgAphhss,
        links: [
            {
                title: 'Потому что могу',
                url: 'https://t.me/yavdrugomgorode',
                icon: <Icon type="telegram" />,
            },
            {
                title: 'Утюг. Цилиндрики. Вандализм',
                url: 'https://instagram.com/yavdrug_omgorode',    
                icon: <Icon type="instagram" />,            
            },
        ]
    },
    'matharm': {
        name: 'Лена Сударикова (Ёлка)',
        img: imgMatharm,
        links: [
            {
                title: 'ЛайвЛаб',
                url: 'https://www.livelib.ru/reader/ElenaSudarikova',
            }
        ]
    },
}

export default members