import classNames from 'classnames'
import SectionHeader from 'component/SectionHeader'

import members from 'data/members'

import imgHeaderBackground from './res/header-background.svg'
import imgHeaderItem1 from './res/header-item-1.svg'
import imgHeaderItem2 from './res/header-item-2.svg'

import './style.css'

const MembersSection = () => (
    <div className="section section_members">
        <SectionHeader 
            className="section_members__header" 
            text="Участники"
            background={imgHeaderBackground}
        >
            <img className="section_members__header-item-1" src={imgHeaderItem1} alt="" />
            <img className="section_members__header-item-2" src={imgHeaderItem2} alt="" />
        </SectionHeader>
        <div className="section_members__list">
        {Object.keys(members).map(key => (
               
                <div className={classNames("section_members__member", members[key].className)}>
                    <img className="section_members__member-img" src={members[key].img} alt={members[key].name} />
                    <div className="section_members__member-name">{members[key].name}</div>
                    {!!members[key].links && members[key].links.map(link => (
                        <div className="section_members__member-link">
                            <a href={link.url} target="_blank" rel="noreferrer">{link.title}{link.icon}</a>
                        </div>
                    ))}
                </div>
                     
        ))}
        </div>
    </div>
)

export default MembersSection