import './style.css'

// import { NavLink as Link } from 'react-router-dom'
import Logo from 'component/Logo'
import Footer from 'component/Footer'

import ChannelSections from './section/Channels'
import GoodNewsSection from './section/GoodNews'
import LectionSection from './section/Lection'
import MembersSection from './section/Members'
import TechnicsSection from './section/Technics'

const IndexRoute = () => <div className="route route_index">
    <div className="index__header">
        <Logo />
        <p>Творческое объединение крутанов. Коллабимся и делаем.</p>
    </div>
    <GoodNewsSection />
    <LectionSection />
    <ChannelSections />
    <TechnicsSection />
    <MembersSection />
    <Footer />
</div>

export default IndexRoute