import Date from 'component/Date'
import Icon from 'component/Icon'
import Roles from 'component/Roles'
import SectionHeader from 'component/SectionHeader'

import imgCover from './res/cover.jpg'
import imgHeaderBackground from './res/header-background.svg'
import imgHeaderItem1 from './res/header-item-1.svg'

import './style.css'

const GoodNewsSection = () => (
    <div className="section section__good-news">
        <div className="section_good-news__cover">
            <img src={imgCover} alt="Подкаст «Хорошие новости»" />
        </div>
        <div className="section_good-news__info">
            <SectionHeader 
                className="section_good-news__header"
                text="Хорошие новости"
                background={imgHeaderBackground}
            >
                <img className="section_good-news__header-item-1" src={imgHeaderItem1} alt="" />
            </SectionHeader>
            <p>Обсуждаем позитивные новости в подкасте. Убийств, скандалов и Байдена и без нас хватает.</p>
            <Roles list={['higimo', 'legostaeff', 'matharm', 'aphhss', 'vatrikovsky']} />
            <Date start='2022' end="2023" />
        </div>
        <div className="section_good-news__links">
            Слушайте <a href="https://www.youtube.com/@good_news_for_everyone" target="_blank" rel="noreferrer"><Icon type="youtube" />на Ютубе</a>, <a href="https://rutube.ru/channel/29701757/videos/" target="_blank" rel="noreferrer"><Icon type="rutube" />Рутубе</a>, <a href="https://music.yandex.ru/album/24484307?dir=desc&activeTab=track-list" target="_blank" rel="noreferrer"><Icon type="yandex-music" />в Яндекс-музыке</a>, <a href="https://t.me/good_news_for_everyone" target="_blank" rel="noreferrer"><Icon type="telegram" />Телеграме</a> или <a href="https://podcasts.apple.com/ru/podcast/%D1%85%D0%BE%D1%80%D0%BE%D1%88%D0%B8%D0%B5-%D0%BD%D0%BE%D0%B2%D0%BE%D1%81%D1%82%D0%B8/id1661526237" target="_blank" rel="noreferrer"><Icon type="apple-podcasts" />Эпл-подкастах</a>
        </div>
    </div>
)

export default GoodNewsSection