import imgIntersection1 from './res/intersection-1.svg'
import imgIntersection2 from './res/intersection-2.svg'
import imgIntersection3 from './res/intersection-3.svg'
import imgIntersection4 from './res/intersection-4.svg'
import imgIntersection5 from './res/intersection-5.svg'

import './style.css'

const Logo = () => (
    <div className="logo">
        <div className="logo__sign">
            <img src={imgIntersection1} alt="" />
            <img src={imgIntersection2} alt="" />
            <img src={imgIntersection3} alt="" />
            <img src={imgIntersection4} alt="" />
            <img src={imgIntersection5} alt="" />
        </div>
        <div className="logo__text">Пересечение</div>
    </div>
)

export default Logo